import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import classes from './footer.module.css'; 

const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.contact}>
          <h2>Kontakt</h2>
          <p><i><FaMapMarkerAlt /></i> Ul. Matice hrvatske 28a</p>
          <p><i><FaPhone /> </i>+385 91 377 7760 (English/Italian)</p>
          <p><i><FaEnvelope /></i> adriaticum.nekretnine@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
