import React from 'react'
import classes from './contact.module.css'
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa'

const Contact = () => {
  return (
    <div className={classes.container}>
      <h2>Kontaktirajte nas</h2>
        <p><i><FaMapMarkerAlt /></i> Ul. Matice hrvatske 28a</p>
        <p><i><FaPhone /> </i>+385 91 377 7760 (English/Italian)</p>
        <p><i><FaEnvelope /></i> adriaticum.nekretnine@gmail.com</p>
    </div>
  )
}

export default Contact